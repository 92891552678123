<template>
  <div>
    <ListaGadget />
  </div>
</template>

<script>
import ListaGadget from '@/components/Sistema/ListaSistema'
export default {
  components: {ListaGadget},
  data(){
    return{}
  }
}
</script>

<style>

</style>